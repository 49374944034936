import { TailwindWidth } from 'api/style/Width.ts';

export const TailwindHeight = Object.freeze(TailwindWidth);

export type TailwindHeight = typeof TailwindWidth[keyof typeof TailwindWidth];

type Height = TailwindHeight | Exclude<string | number, TailwindHeight>;

export const HeightClass = (height: Height, important?: boolean) =>
    `${important ? '!' : ''}h-${
        Object.values(TailwindHeight).includes(height as any)
            ? height
            : (typeof height == 'string' ? `[${height}]` : `[${height}px]`)
    }`;

export default Height;
